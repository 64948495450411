<template>
<div class="pa-2">
    <v-row justify="end">
        <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
            <el-button v-if="params.filter && Object.keys(params.filter).length>0" type="plain" @click="clearFilter" color="error">
                <v-icon small>close</v-icon> Clear Filters
            </el-button>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="6">
            <el-input suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search" @keyup.enter.native="getScopes()">
                <template slot="prepend">

                    <el-button @click="createScope()"><i class="el-icon-plus"></i> Create Scope</el-button>
                </template>
            </el-input>
        </v-col>
    </v-row>
    <el-container style="height: 75vh">
        <el-main>
             <v-data-table :footer-props="{'items-per-page-options':[15, 30, 50, 100]}" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" dense :loading="loading" :items="data.data" :headers="headers">
                <template v-slot:[`item.action`]="{ item }">
                    <el-button plain @click="editRoute(item)" size="small" icon="el-icon-edit" circle></el-button>
                    <el-button size="small" type="danger" icon="el-icon-delete" circle @click="removeRoute(item)"></el-button>
                </template>
                <template v-slot:[`item.isActive`]="{ item }">
                        <el-tag style="cursor: pointer" size="small" @click="changeActiveStatus(item)" :type="item.isActive ? 'primary' : 'danger'">{{item.isActive?'Active': 'Inactive'}}</el-tag>
                </template>
             </v-data-table>
        </el-main>
        <!-- <el-footer>
            <el-row justify="center" type="flex">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :page-size.sync="params.limit" :page-sizes="[10, 25, 50, 100]" layout="sizes, prev, pager, next, jumper" :total="data.total">
                </el-pagination>
            </el-row>
        </el-footer> -->
    </el-container>

    <el-dialog :visible.sync="modal" width="750px" :fullscreen="$vuetify.breakpoint.mobile" :title="item.modalType + ' Scope'" :closed="clear">
        <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveItem">

            <el-form-item prop="name">
                <el-input v-model="item.name" placeholder="Name" clearable maxlength="45"
  show-word-limit>
                    <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
            <el-form-item prop="description">
                <el-input v-model="item.description" placeholder="Description" type="textarea" clearable  maxlength="500"
  show-word-limit>
                    <v-icon v-if="item.description" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>
           

            <el-form-item style="text-align: center">
                <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Submit':''}}</el-button>
                <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Save':''}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
export default {
    data: () => ({
        headers: [
{
                text: 'Action',
                align: 'center',
                sortable: false,
                value: 'action'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center'
            },
            {
                text: 'Description',
                value: 'description',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Status',
                value: 'isActive',
                align: 'center'
            }
        ],
        loading: false,
        page: 1,
        params: {
            limit: 10,
            offset: 0,
            search: null
        },
        item: {},
        data: {
            count: 0,
            data: []
        },
        modal: false,
        sortBy: 'name',
        sortDesc: false
    }),
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getScopes()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                await this.getScopes()

            }
        }
    },
    async created() {
        await this.getScopes()
        this.loading = false
    },
    methods: {
        async changeActiveStatus(item) {
            item.isActive = !item.isActive
            await this.$API.updateScope({
                id: item.id,
                isActive: item.isActive
            })
            this.$message({
                type: 'success',
                message: 'Successfully updated!'
            });
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
            this.params.filter = {}
            this.getScopes()
        },
        createScope() {
            this.item = {
                modalType: 'Add',
                admin: false,
                protected: true
            }
            this.modal = true
        },
        editRoute(org) {
            this.item = org
            this.item.modalType = 'Edit'

            this.modal = true
        },
        filterChange(data) {
            this.params.filter = data
            this.getScopes()
        },
        async getScopes() {
            this.loading = true

            this.data = await this.$API.getScopes({
                params: this.params
            })
            this.loading = false
        },
        removeScope(org) {
            console.log(org)
            this.$confirm('Are you sure you want to delete this Route?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateScope({
                    id: org.id,
                    isActive: false,
                    isDeleted: true
                })
                this.getScopes()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async saveItem() {
            this.loading = true
            if (this.item.id) {
                await this.$API.updateScope(this.item)
                this.$message.success('Update successful!')

            } else {
                await this.$API.createScope(this.item)
                this.$message.success('Successfully created scope!')
                this.getScopes()
            }
            this.loading = false
            this.item = {}
            this.modal = false
        },
        clear() {
            this.loading = false
            this.item = {}
            this.modal = false
        }
    }
}
</script>

<style scoped>
.modal-form {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
}
</style>
